import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import { PageTitle, PageTitle as TitleText } from '../../components/PageTitle';
import Text from '../../components/Text';
import { UserControl as CompanyControl } from '../../components/UserControl';
import { useRootStore } from '../../context/storeContext';
import { normalizeVideoAdvSize } from '../../utils';
import { OrgSubscriptionCard } from '../AdminOrganizations/components/OrgSubscriptionCard';
import {
  UserContainer as Container,
  DetailsListsContainer,
  TitleWithControlContainer,
} from '../Common/styles';
import { QuickNavigationBar } from './components/QuickNavigationBar';

const DashBoardPageContainer = styled(Container)`
  height: calc(100vh - 160px);

  & .gd-video {
    height: calc(100vh - 500px);
  }
`;

const CardContainer = styled.div`
  padding-right: 40px;
  width: 100%;

  & .ant-card-bordered {
    border: unset;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  width: 100%;
  height: 100%;
  padding-top: 10px;

  & .video-player {
    width: 100%;
    height: 100%;
    display: flex;

    & > iframe {
      position: absolute;
      border-radius: 4px;
    }
  }
`;

export const AdvTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: absolute;

  & > span {
    font-size: 5vh;
  }
`;
const DescriptionContainer = styled.div`
  padding-top: 5px;
`;

const CompanyDescription = ({ description }: { description: string }) => {
  const theme = useTheme();
  return (
    <DescriptionContainer>
      <Text fontSize={18} opacity={0.35} fontFamily={theme.fonts.semiBold}>
        {description}
      </Text>
    </DescriptionContainer>
  );
};

export const DashBoardPage = observer(() => {
  const { colors } = useTheme();
  const {
    companyStore,
    authStore: { signedInUser },
    authorizeStore,
  } = useRootStore();

  const {
    planName,
    totalStorageSize,
    totalUsers,
    limitStorageSize,
    limitUsers,
    subscriptionEndDate,
    hasShareCare,
  } = companyStore.subscriptionData;

  const videoRef = useRef<HTMLIFrameElement>(null);
  const advertRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement>(null);

  const isAutoPlayVideo =
    Number(localStorage.getItem('signInCount')) < 1 ? 1 : 0;
  const videoSrc = `https://player.vimeo.com/video/671901605?&autoplay=${isAutoPlayVideo}`;

  useEffect(() => {
    const fixSizes = () => {
      normalizeVideoAdvSize(videoRef, advertRef);
      if (cardsRef.current && videoRef.current) {
        cardsRef.current.style.width = videoRef.current.style.width;
      }
    };
    normalizeVideoAdvSize(videoRef, advertRef);
    if (cardsRef.current && videoRef.current) {
      cardsRef.current.style.width = videoRef.current.style.width;
    }
    window.addEventListener('resize', fixSizes);
    return () => {
      window.removeEventListener('resize', fixSizes);
    };
  }, [videoRef, advertRef]);

  const companyDomain = signedInUser.email?.split('@')[1] as string;

  const history = useHistory();

  const onUpgradePlanClicked = useCallback(() => {
    history.push(`company/manage-subscription`);
  }, [history]);

  return (
    <DashBoardPageContainer>
      <Row>
        <TitleWithControlContainer>
          <CompanyControl
            size={64}
            name={
              <PageTitle
                title={signedInUser.companyName as string}
                includeDot={false}
              />
            }
            description={
              <CompanyDescription description={`@${companyDomain}`} />
            }
            direction="reverse"
            image={companyStore.companyDetails.logo?.src}
          />
        </TitleWithControlContainer>
      </Row>

      <CardContainer>
        <Col ref={cardsRef}>
          {authorizeStore.isAuthorizedToViewDashboard() && (
            <>
              <QuickNavigationBar />
              <DetailsListsContainer>
                <Card className="gd-card">
                  <OrgSubscriptionCard
                    planName={planName as string}
                    totalUsers={totalUsers as number}
                    limitUsers={limitUsers as number}
                    totalStorageSize={totalStorageSize as number}
                    limitStorageSize={limitStorageSize as number}
                    subscriptionEndDate={subscriptionEndDate ?? dayjs(0)}
                    onUpdatePlan={onUpgradePlanClicked}
                    hasShareCare={hasShareCare! as boolean}
                  />
                </Card>
              </DetailsListsContainer>
            </>
          )}
        </Col>
      </CardContainer>

      <Row className="gd-video">
        <ContentContainer>
          <Col span={24} className="video-player">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              ref={videoRef}
              src={videoSrc}
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
            />
            <AdvTextContainer ref={advertRef}>
              {/* Hello World
              <TitleText
                title="Wir bringen"
                includeDot={false}
                textSize={49}
                bgColor={colors.thinOrange}
              />
              <TitleText
                title="mehr Spaß"
                includeDot={false}
                textSize={49}
                bgColor={colors.thinOrange}
              />
              <TitleText
                title="bei der Arbeit"
                includeDot={false}
                textSize={49}
                bgColor={colors.thinOrange}
              />
               */}
            </AdvTextContainer>
          </Col>
        </ContentContainer>
      </Row>
    </DashBoardPageContainer>
  );
});
